/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IModule = angular.IModule;

// Add the `appendTransform` method to the global `angular` object.
angular.appendTransform = <T extends Function>(defaults: T|T[], ...transforms: T[]) => {

    // We can't guarantee that the default transformation is an array.
    let defaultsArray = (defaults instanceof Array) ? defaults : [<T>defaults]; // The compiler needs some help knowing that it must be a T here.

    // Append the new transformation to the existing array of transforms.
    return defaultsArray.concat(transforms);
};

// Store off the original `module` method.
let originalModuleMethod = angular.module;

// Then, replace the `module` method with a new implementation.
angular.module = function () {
    // Delegate to the original `module` method.
    let moduleInstance = originalModuleMethod.apply(angular, arguments);

    // Decorate the module instance with the new method that allows a service to be used as a filter.
    moduleInstance.serviceAsFilter = (name: string, serviceConstructor: Function|any[], methodName: string = 'filter'): IModule => {
        // We first have to register the service (as a service) so that it can be injected into the filter later.
        // We can use the same name for both because, under the hood, Angular will append 'Filter' to the name when registering it as a filter.
        moduleInstance.service(name, serviceConstructor);

        // Finally, we register the filter. The service is injected in and a single method from it is used as the filter.
        return moduleInstance.filter(name, [name, (service: any) => service[methodName].bind(service)]);
    };

    return moduleInstance;
};
