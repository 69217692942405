// jQuery must come before Angular, and it must be global in order for Anguar to use it.
window['jQuery'] = require('jquery'); // eslint-disable-line dot-notation, @typescript-eslint/dot-notation

// Relinquish the `$` global variable.
jQuery.noConflict();

// Angular + Angular modules.
import 'angular';
import 'angular-animate';
import 'angular-media-queries';
import 'angular-messages';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-ui-event';
import 'angular-ui-router';
import 'ng-file-upload';
import 'ng-pattern-restrict/src/ng-pattern-restrict.min.js';
import './app/polyfills/angular-extensions';

// Ionic
import 'ionic-sdk/release/js/ionic.js';
import 'ionic-sdk/release/js/ionic-angular.js';
import { SpinnerDialog } from 'ionic-native/dist/es5/plugins/spinnerdialog';
import { Transfer } from 'ionic-native/dist/es5/plugins/filetransfer';
import { initAngular1 } from 'ionic-native/dist/es5/ng1';
initAngular1({
    SpinnerDialog,
    Transfer
});

// Other third-party libraries
import 'lodash/curry';
import 'lodash/curryRight';
import 'lodash/debounce';
import 'lodash/find';
import 'lodash/groupBy';
import 'lodash/last';
import 'lodash/padEnd';
import 'lodash/partition';
import 'lodash/remove';
import 'lodash/toPairs';
import 'lodash/trimEnd';
import 'lodash/trimStart';
import 'lodash/uniq';
import 'lodash/uniqBy';

import 'lodash/fp/countBy';
import 'lodash/fp/flow';
import 'lodash/fp/identity';
import 'lodash/fp/map';
import 'lodash/fp/orderBy';
import 'lodash/fp/toPairs';

require('moment');
require('urijs');
import 'sprintf-js';
import 'intl';
import 'intl/locale-data/jsonp/en';

// Angular 2
import '@angular/common';
import '@angular/compiler';
import '@angular/core';
import '@angular/forms';
import '@angular/http';
import '@angular/platform-browser';
import '@angular/platform-browser-dynamic';
// import '@angular/router'; // TODO: Uncomment this when we're ready to use it.
import '@angular/upgrade';

// Angular 2 vendor libraries
import './rxjs-operators';
import 'primeng/components/autocomplete/autocomplete';
